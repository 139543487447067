<template>


<div class="container">
    <!-- Add Absence -->
    <form id="formAddEmployee">
        <modal-add-edit-absence modalId="modalAddAbsence" title="Novo odsustvo" mode="ADD" :absence.sync="newAbsence" @confirmed="createAbsebce"></modal-add-edit-absence>
    </form>

    <!-- Edit Absence -->
    <form id="formEditEmployee">
        <modal-add-edit-absence ref="modalEditAbsence" modalId="modalEditAbsence" title="Izmena odsustva" mode="EDIT" :absence.sync="editedAbsence" @confirmed="updateAbsence"></modal-add-edit-absence>
    </form>
    
    <modal-confirm @confirm="deleteSelectedAbsence" inputId="confirmModal" title="Potvrda brisanja odsustva" message="Da li ste sigurni da želite da obrišete odustvo?"/>

    <h3>Odsustva</h3>

    <div class="col-lg-12 control-section" v-if="absences">
        <div>
            <ejs-grid
                ref="absencesGrid"
                :dataSource="absences" 
                class="absences-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :groupSettings='groupOptions'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :toolbar='toolbar'
                :toolbarClick='clickHandler'
                :recordDoubleClick="absenceDBClick"
                :queryCellInfo='customiseCell'
                :allowTextWrap='true'
                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>
                    <!-- <e-column field='employee.first_name' headerText='Ime' width='120' textAlign='Left'></e-column>
                    <e-column field='employee.last_name' headerText='Prezime' width='120'></e-column> -->

                    <e-column field='start_date' :valueAccessor='dateAccessor' headerText='Početak' width='130' textAlign='Right' :visible='true'></e-column>
                    <e-column field='end_date' :valueAccessor='dateAccessor' headerText='Kraj' width='130' textAlign='Right' :visible='true'></e-column>
                    <e-column field='absence_type' headerText='Razlog' :valueAccessor='absenceTypeAccessor' width='120'></e-column>
                    <e-column field='comment' headerText='Komentar' width='150'></e-column>
                    <e-column field='absence_status' :valueAccessor='absenceStatusAccessor' headerText='Status' width='120'></e-column>


                    <e-column field='approved_at' :valueAccessor='dateTimeAccessor' headerText='Obradjeno' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Izmenjeno' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Napravio zahtev' width='130' :visible='false'></e-column>

                </e-columns>
            </ejs-grid>
        </div>


    </div>



</div>
</template>

<script>
import EmployeesService from '@/service/EmployeesService';
import ModalAddEditAbsence from '@/components/modals/ModalAddEditAbsence';
import AbsenceOptionsTemplate from '@/components/grid-templates/AbsenceOptionsTemplate.vue';
import ModalConfirm from '@/components/modals/ModalConfirm.vue';

import { Query } from "@syncfusion/ej2-data"; 

import { Modal } from 'bootstrap';

import UtilService from '@/service/UtilService.js';
import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit } from "@syncfusion/ej2-vue-grids";
import {mapState} from 'vuex'

let country = [ 
    { countryName: "United States", countryId: "1" }, 
    { countryName: "Australia", countryId: "2" }, 
    { countryName: "India", countryId: "3" }, 
]; 
export default {

    name: "EmployeeAbsences",
    props: ['employee'],
    components: { ModalAddEditAbsence, ModalConfirm },

    provide: {
        grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit ]
    },

    data() {
        return {
            test: this.employee,
            id: null,
            selectedJob: null,
            absences: null,
            newAbsence: {
                employee: this.employee,
            },
            editedAbsence: null,
            modalAddAbsebce: null,
            modalEditAbsebce: null,
            confirmModal: null,
            
            groupOptions: { showGroupedColumn: true },
            filterSettings: { type: "Excel" },
            pageSettings: { pageCount: 20},
            // dateformatoptions: { type: 'dateTime', format: 'yMd' },
            dateformatoptions: { type: 'date'},
            editAbsenceType: {params: {
                
                dataSource: [{value: "VACATION", text: "Odmor"}, {value: "SICKNESS", text: "Bolovanje"}, {value: "RELIGIOUS", text: "Verski praznik"}, {value: "OTHER", text: "Drugo"}], 
                fields: { text: "text", id: "value" }, 
                query: new Query(), 
                actionComplete: () => false, 
            }},
            countryParams: {
                params:   {
                    allowFiltering: true,
                    dataSource: country,
                    fields: {text:"countryName",value:"countryName"},
                    query: new Query(),
                    actionComplete: () => false
                    }
            },

            // toolbar: ['Add', 'Edit', 'Delete', 'Update', 'Cancel'],
            toolbarAdmin: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addAbsebce' }, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editAbsence'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteAbsence'}],
            toolbarEmp: [{ text: 'Zahtev za odsustvo', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addAbsebce' }, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteAbsence'}],


            absenceOptions: function() {
                return { template: AbsenceOptionsTemplate }
            }
        }
    },

    computed: {
        ...mapState([
            'user',
        ]),

        myProfile() {
            return this.user.id == this.employee.id;
        },

        toolbar() {
            if (this.user.role == "ADMIN") return this.toolbarAdmin;
            if (this.myProfile) return this.toolbarEmp;
            return [];
        }
    },

    created() {
        this.loadUserAbsences();

    },

    mounted() {
        this.modalAddAbsebce = new Modal(document.getElementById('modalAddAbsence'));
        this.modalEditAbsebce = new Modal(document.getElementById('modalEditAbsence'));
        this.confirmModal = new Modal(document.getElementById('confirmModal'));
    },

    methods: {
        formatDate : UtilService.formatDate,
        formatTime : UtilService.formatTime,
        formatDateTime : UtilService.formatDateTime,

        getLocationFullindex : UtilService.getLocationFullindex,
        getPositionFullindex : UtilService.getPositionFullindex,
        fullNameList : UtilService.fullNameList,


        fullnameAccessor(field, data) {
            let firstField = field.split('.')[0];
            let userData = data?.[firstField];
            console.log(userData);
            if (!userData?.first_name) return;
            return userData.first_name + " " + userData.last_name; 
        },

        dateTimeAccessor(field, data) {
            return data[field] ? this.formatDateTime(data[field]) : '/';
        },
        dateAccessor(field, data) {
            return data[field] ? this.formatDate(data[field]) : '/';
        },

        absenceTypeAccessor(field, data) {


            return UtilService.translateAbsenceType(data[field]);
        },


        absenceStatusAccessor(field, data) {
            let approved = data.approved_by?.username;


            let status = data[field] ? UtilService.translateAbsenceStatus(data[field]) : '/';
            if (approved)
                return status + " ( " + approved + " )"
            else return status;
        },

        

        absenceDBClick(event) {
            if (this.user.role == "ADMIN" || (this.myProfile && (event.rowData.absence_status == "ADMIN_CHANGE" || event.rowData.absence_status == "REQUESTED"))) {
                this.editedAbsence = event.rowData;
                this.showEditAbsenceModal();
            }
        },

        customiseCell(args) {
                let status = args.data?.absence_status;
                if (status == "REQUESTED")
                    args.cell.classList.add('abs-requested');
                else if (status == "APPROVED")
                    args.cell.classList.add('abs-approved');
                else if (status == "REJECTED")
                    args.cell.classList.add('abs-rejected');
                else if (status == "ADMIN_CHANGE")
                    args.cell.classList.add('abs-change');
                
        },


        async loadUserAbsences() {
            this.absences = null;
            try {
                let result = await EmployeesService.getAbsences({'employee_id' : this.employee.id})
                this.absences = result.data;
            } catch (error) {
                console.log(error);
                alert("error");
            }
        },


        async createAbsebce() {
            try {
                this.newAbsence.employee_id = this.newAbsence.employee.user.id;

                let result;
                if (this.myProfile) result = await EmployeesService.postAbsenceRequest(this.newAbsence)
                else {
                    if (!this.newAbsence.absence_status)
                        this.newAbsence.absence_status = "DEFAULT";
                    result = await EmployeesService.postAbsence(this.newAbsence)
                }

                this.newAbsence = result.data;
                this.modalAddAbsebce.hide();   
                this.toast('Uspešno kreirano odsustvo.');
                this.loadUserAbsences();
        } catch (error) {
                alert("Neuspesno")
            }
        },
        
        async updateAbsence() {
            try {
                this.editedAbsence.employee_id = this.editedAbsence.employee.user.id;

                let result;
                if (this.myProfile){
                    this.editedAbsence.absence_status = "REQUESTED";
                    result = await EmployeesService.postAbsence(this.editedAbsence)  
                } 
                else {
                    result = await EmployeesService.postAbsence(this.editedAbsence)
                }

                this.editedAbsence = result.data;
                this.modalEditAbsebce.hide();   
                this.toast('Uspešno izmenjeno odsustvo.');
                this.loadUserAbsences();
            } catch (error) {
                    console.log(error)
                    alert("Neuspesno")
                }
            },
        
        async deleteAbsence(data) {

            try {
                data.employee_id = data.employee.user.id;
                await EmployeesService.deleteAbsence(data)
                this.toast('Uspešno brisanje odsustva.');
                this.loadUserAbsences();
            } catch (error) {
                    if (this.user.role != "ADMIN") alert("Samo svoje zahteve mozete da brisete");
                    console.log(error)
                    alert("Neuspesno")
            }
        },

        actionBegin(args) {
            console.log(args)
            if (args.requestType === 'save') {
                if (this.$refs.grid.ej2Instances.pageSettings.currentPage !== 1) {
                    args.index = (this.$refs.grid.ej2Instances.pageSettings.currentPage * this.$refs.grid.ej2Instances.pageSettings.pageSize) - this.$refs.grid.ej2Instances.pageSettings.pageSize;
                }
            }
        },

        clickHandler(args) {
            if (args.item.id === 'addAbsebce') {
                this.showAddAbsenceModal()
            }

            if (args.item.id === "editAbsence"){
                let selected = (this.$refs.absencesGrid.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;

                this.editedAbsence = selected;

                this.showEditAbsenceModal();
            }

            if (args.item.id === "deleteAbsence"){
                let selected = (this.$refs.absencesGrid.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;
                
                this.confirmModal.show();
            }
        },

        deleteSelectedAbsence(){
                let selected = (this.$refs.absencesGrid.ej2Instances.getSelectedRecords());
                if (selected.length > 0) selected = selected[0];
                else return;

                this.deleteAbsence(selected)

        },

        showAddAbsenceModal() {
            this.newAbsence = {
                employee: this.employee,
            },

            this.modalAddAbsebce.show();   
        },

        showEditAbsenceModal() {
            this.$refs.modalEditAbsence.load();
            this.modalEditAbsebce.show();   
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        },

    },
}
</script>

<style>

    .absences-grid table .abs-approved {
        background: #0dff00;
    }
    .absences-grid table tr:hover .abs-approved {
        background: #0cdd01 !important;
    }


    .absences-grid table .abs-rejected {
        background: #961600;
    }
    .absences-grid table tr:hover .abs-rejected {
        background: #7b1200 !important;
    }

    .absences-grid table .abs-change {
        background: #00f2ff;
    }
    .absences-grid table tr:hover .abs-change {
        background: #00afb8 !important;
    }

    .absences-grid table .abs-requested {
        background: #ffcb71;
    }
    .absences-grid table tr:hover .abs-requested {
        background: #cfa45a !important;
    }




    .absences-grid table tr .abs-approved.e-active {
        background: #0bbd02 !important;
    }

    .absences-grid table tr .abs-rejected.e-active {
        background: #6d1100 !important;
    }

    .absences-grid table tr .abs-change.e-active {
        background: #009da5 !important;
    }
    
    .absences-grid table tr .abs-requested.e-active {
        background: #b6904e !important;
    }


</style>