<template>
    <div>
        <button type="button" class="btn">
            <i class="fas fa-edit fa-lg" />
        </button>
        <button type="button" class="btn">
            <i class="fas fa-trash fa-lg" />
        </button>
    </div>
</template>

<script>
export default {
    name: 'grid-username-template',

    data: function() {
        return {
            data: {},
        }
    }
}
</script>
